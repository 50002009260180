import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { navigate, Link } from "gatsby"

const Navbar = () => {
    return (
    <div className='branding-container mx-auto p-10 h-25 flex flex-wrap items-center justify-center lg:justify-between md:justify-between space-y-5 lg:space-y-0 space-x-5'>
        <Link to='/'><StaticImage src='../images/sju-text-full.png' alt="Saint Joseph's University logo"/></Link>
        <div className='flex flex-col lg:flex-row items-center space-y-5 lg:space-y-0 space-x-0 lg:space-x-16'>
            {/* <div className='flex space-x-16'>
                <a href='/programs' className='text-lg font-bold hover:text-executive-primary'>PROGRAMS</a>
                <a href='/faqs' className='text-lg font-bold hover:text-executive-primary'>FAQS</a>
            </div> */}
            <div className='flex flex-row flex-wrap justify-center items-end space-x-0 space-y-5 lg:space-y-0 md:space-x-8 lg:space-x-8'>
                <div className='flex flex-col'>
                    {/* <p className='font-bold'>ENROLL</p> */}
                    <select onChange={(e) => navigate(e.target.value)} name='enroll' id='enroll' className='p-3 md:w-50 w-56 text-lg border-2 hover:bg-executive-primary hover:text-white' style={{outline: '0 none'}} >
                        <option value="" readOnly={true}>--Enroll Now--</option>
                        <option value="https://admission.sju.edu/register/ETCP-PM">Evening Cohort</option>
                    </select>
                </div>
                <div className='flex items-center border-2 border-executive-primary py-3 pr-5 pl-5'>
                    <a href='tel:+1-503-360-6700' className='pr-5 text-executive-primary'>+1 (503) 360-6700</a>
                    <FontAwesomeIcon icon={faPhone} className='text-executive-primary'/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Navbar;