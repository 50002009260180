import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import Button from "../components/basic/button"

const Footer = () => {
    return (
        <footer className='text-executive-primary'>
            <div className="w-full grid grid-cols-1 gap-y-10 md:grid-cols-2 lg:grid-cols-3 p-5 bg-gray-200 py-5">
                <div className="flex justify-center items-center">
                    <div>
                        <StaticImage src='../images/sju-text-full.png' alt="Saint Joseph's University logo" />
                        <div className="street text-executive-primary flex flex-col justify-center items-center">
                            <span>Saint Joseph’s University</span>
                            <span>5600 City Ave.</span>
                            <span>Philadelphia, PA 19131</span>
                            <span>(610) 660-1000</span>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <Button type='sju3' size='lg' icon={<FontAwesomeIcon icon={faLongArrowAltRight} className='ml-3'/>} iconDirection='right' href='https://www.sju.edu/'>Return to Saint Joseph's University Home</Button>
                </div>
                <div className="md:col-span-2 lg:col-span-1">
                    <div className="street text-executive-primary flex flex-col justify-center items-center p-10 ">
                        <span>Powered by</span>
                        <StaticImage src='../images/coach-training-logo.png' alt='Coach Training EDU Logo' className="w-32 h-32 my-5" />
                        <p className="border-b-2 border-black">2022 Coach Training EDU™</p>
                    </div>
                </div>
            </div>
            <div className="w-full h-32 text-white bg-executive-primary flex justify-around py-4 font-oswald">
                <span>© 2022 Coach Training EDU™</span>
                <div className='flex flex-col'>
                    <a href="https://www.coachtrainingedu.com/policies/privacy-policy">Privacy Policy</a>
                    <a href="https://www.coachtrainingedu.com/about/ctedu-diversity-equity-and-inclusion-statement/">Diversity & Inclusion</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer