import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import UserWay from './UserWay'

const Layout = ({children}) => {
    return (<>
        <Navbar/>
        {children}
        <UserWay/>
        <Footer/>
    </>)
}

export default Layout;