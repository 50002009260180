import React from "react"
import { Link } from 'gatsby'

const type = {
    primary: "button-text uppercase bg-newBranding-almostWhite hover:bg-newBranding-lightBlue hover:bg-opacity-30 text-newBranding-darkGreen border-newBranding-darkGreen rounded-3xl border-2 text-center",
    secondary: "button-text uppercase bg-newBranding-orange bg-opacity-5 hover:bg-opacity-20 text-newBranding-orange border-newBranding-orange rounded-3xl border-2 text-center",
    third: "button-text uppercase bg-white hover:bg-opacity-20 text-newBranding-jet border-white rounded-3xl border-2 text-center",
    sju: "button-text uppercase bg-none hover:bg-white hover:text-black text-white border-white border-2 text-center",
    sju2: "block button-text uppercase text-white border-2 border-white p-2 px-4 hover:bg-executive-primary hover:text-white",
    sju3: "button-text uppercase text-executive-primary border-2 border-executive-primary p-2 px-4 hover:bg-executive-primary hover:text-white",
    sju4: "button-text uppercase bg-executive-primary text-white border-2 border-executive-primary p-2 px-4 hover:bg-white hover:text-executive-primary",
};

 const size = {
  xs: "px-4 py-0",
  sm: "py-3 pl-5 pr-5",
  lg: "py-3 pl-5 pr-5"
};

const Button = React.forwardRef((props,ref)=> {
    const classNames = type[props.type] + " " + size[props.size];
    if (props.href) {
      return (
        <div className='flex'>
          <a className={classNames} href={props.href}>
                <h4 className='button-text'>
                    {props.icon && !props.iconDirection && props.icon}
                    {props.children}
                    {props.icon && props.iconDirection && props.icon}
                </h4>
          </a>
        </div>)
    }
  
    if (props.to && props.attributeType) {
        return (
            <Link to={props.to}>
                <button type={props.attributeType} className={classNames}>
                  {props.children}
                </button>
            </Link>
        )
    }
  
    if(props.to){
      if (props.includeLinkH4) {
          return (
              <Link to={props.to}>
                  {props.children}                
              </Link>
            )
      }
  
      return (
        <div className='flex'>
          <Link
            style={{outline: '0 none'}}
            className={classNames}
            to={props.to}
          >
            {props.children}
          </Link>
        </div>
        )
    }
  
    return (
        <button style={{outline: '0 none'}} className={classNames} ref={ref} type='submit'>{/*type={props.attributeType} */}
            {props.icon && !props.iconDirection && props.icon}
            {props.children}
            {props.icon && props.iconDirection && props.icon}
        </button>
    )
  }
)

export default Button;