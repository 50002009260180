import React, { useEffect, useState } from "react";

const loadDynamicScript = (callback) => {
  const existingScript = document.getElementById('user-way');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://accessibilityserver.org/widget.js';
    var a = document.createAttribute("data-account");
    a.value = "wdO5pQriKr";
    script.setAttributeNode(a);
    // script['data-account'] = 'wdO5pQriKr';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

export const UserWay = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadDynamicScript(() => {
      // window.intercomSettings = {
      //   app_id: "le60bl9e"
      // };
      setLoaded(true);
    });
  });

  return (
    <div id='user-way-element'>
        {loaded ? '':''}
    </div>
  );
};

export default UserWay;

